






const defaultLayout = 'default'
declare global {
  interface Window {
    webpackHotUpdate: any
  }
}

import { Component, Vue } from 'vue-property-decorator'

@Component
export default class App extends Vue {
  get layout() {
    return (this.$route.meta.layout || defaultLayout) + '-layout'
  }
}
