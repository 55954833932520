import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
Vue.use(Vuex)

import { RootState } from '@/store/types'
import { issuesList } from '@/store/issues/list'
import { auth } from '@/store/auth'
import { product } from '@/store/product'

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    issuesList,
    product
  }
}

export default new Vuex.Store<RootState>(store)
