import { ActionTree } from 'vuex'
import { RootState } from '../types'
import { ProductState } from './types'
import { Product } from '@/models/Product.model'

export const actions: ActionTree<ProductState, RootState> = {
  setAvailableProducts({}, products: Product[]) {
    this.commit('product/setAvailableProducts', products)
    if (localStorage.currentProductIri) {
      const filteredProducts = products.filter(product => product.iri() === localStorage.currentProductIri)
      if (filteredProducts.length === 1) {
        this.commit('product/setCurrentProduct', filteredProducts[0])
      } else {
        this.commit('product/setCurrentProduct', products[0])
      }
    } else {
      this.commit('product/setCurrentProduct', products[0])
    }
  },
  setCurrentProductByIri({ state }, productIri: string) {
    if (!state.availableProducts) {
      throw new Error('Cannot set current product by iri ' + productIri + 'because availableProducts are empty')
    }
    const products = state.availableProducts.filter(product => productIri === product.iri())
    if (products.length === 1) {
      this.commit('product/setCurrentProduct', products[0])
    }
  }
}
