import { AxiosResponse } from 'axios'
import { HasIri } from '@/models/interface/HasIri'
import { Issue } from '@/models/Issue.model'
import { IssueService } from '@/main/issues/services/IssueService'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export class Sprint implements HasIri {
  id?: string
  name: string
  goal: string
  startAt: any
  endAt: any
  issues: Issue[]

  constructor(data: any, uri?: string) {
    this.id = uri
    this.name = data.name
    this.goal = data.goal
    this.startAt = data.startAt
    this.endAt = data.endAt
    this.issues = data.issues
  }

  iri(): string {
    return this.id ? this.id : ''
  }

  static async createFromResponse(response: AxiosResponse): Promise<Sprint> {
    const sprint = new Sprint(response.data, response.data['@id'])
    sprint.issues = await IssueService.getIssuesForSprint(sprint)

    return sprint
  }

  static createFromSprint(sprint: Sprint): Sprint {
    return new Sprint(
      {
        name: sprint.name,
        goal: sprint.goal,
        startAt: sprint.startAt,
        endAt: sprint.endAt,
        issues: sprint.issues
      },
      sprint.id
    )
  }

  pointsSum(): number {
    return this.countEstimation(this.issues)
  }

  private countEstimation(issues: Issue[]) {
    return issues?.reduce(
      (currentValue, issue) => (issue.estimation === null ? 0 : parseInt(issue.estimation)) + currentValue,
      0
    )
  }

  pointsDone(): number {
    return this.countEstimation(this.issuesDone())
  }

  issuesDone(): Issue[] {
    return this.issues.filter(issue => issue.currentStatus === 'done')
  }

  percentDone() {
    return (this.pointsDone() / this.pointsSum()) * 100
  }

  timeLeft() {
    if (this.endAt) {
      return dayjs().to(dayjs(this.endAt))
    }
    return '---'
  }

  isActive(): boolean {
    return false
  }
}
