import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, length, min, max } from 'vee-validate/dist/rules'
import passwordEquals from '@/validators/passwordEquals'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('password', passwordEquals)
extend('email', email)
extend('length', length)
extend('min', min)
extend('max', max)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
