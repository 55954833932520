import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { IssuesListState } from '@/store/issues/list/types'
import { actions } from './actions'
import { mutations } from './mutations'

export const state: IssuesListState = {
  list: [],
  page: 1
}

const namespaced = true

export const issuesList: Module<IssuesListState, RootState> = {
  namespaced,
  state,
  actions,
  mutations
}
