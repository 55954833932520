import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { actions } from './actions'
import { mutations } from './mutations'
import { ProductState } from '@/store/product/types'

export const state: ProductState = {
  currentProduct: undefined
}

const namespaced = true

export const product: Module<ProductState, RootState> = {
  namespaced,
  actions,
  state,
  mutations
}
