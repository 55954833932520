






















import { Component, Vue } from 'vue-property-decorator'
import { Product } from '@/models/Product.model'
import ActiveSprintButtons from '@/main/sprint/component/ActiveSprintButtons.vue'

@Component({
  components: { ActiveSprintButtons }
})
export default class Sidebar extends Vue {
  private items = [
    { title: this.$i18n.t('dashboard.title'), to: { name: 'Dashboard' }, icon: 'mdi-view-dashboard' },
    { title: this.$i18n.t('issue.title'), to: { name: 'ProductBacklog' }, icon: 'mdi-list-status' },
    { title: this.$i18n.t('sprint.title'), to: { name: 'SprintList' }, icon: 'mdi-run' },
    { title: this.$i18n.t('product.title'), to: { name: 'ProductList' }, icon: 'mdi-monitor-cellphone-star' },
    { title: 'Changelog', to: { name: 'Changelog' }, icon: 'mdi-post-outline' }
  ]

  get currentProduct(): Product {
    return this.$store.state.product.currentProduct
  }
}
