import { ActionTree } from 'vuex'
import axios, { AxiosResponse } from 'axios'
import { AuthState } from './types'
import { RootState } from '../types'
import { User } from '@/models/User.model'
import { ProductService } from '@/main/product/services/ProductService'

export const actions: ActionTree<AuthState, RootState> = {
  async login({ dispatch }, loginData) {
    const responseLogin = await axios.post('/login', {
      email: loginData.email,
      password: loginData.password
    })
    const responseUser = await axios.get<AxiosResponse>(responseLogin.headers.location)
    await dispatch('initializeSystem', responseUser)
  },
  async checkIsAuthenticated({ state, dispatch, commit }) {
    if (state.isAuthenticated === undefined) {
      try {
        const responseCurrentUser = await axios.get<AxiosResponse>('/currentUser')
        await dispatch('initializeSystem', responseCurrentUser)
      } catch (error) {
        console.error(error)
        commit('removeUser')
      }
    }
  },
  async initializeSystem({ dispatch, commit }, responseCurrentUser) {
    const products = await ProductService.getProducts()
    if (products.length > 0) {
      dispatch('product/setAvailableProducts', products, { root: true })
    }
    commit('setUserFromResponse', responseCurrentUser)
  },
  async register({}, registerData) {
    await axios.post('/users', {
      email: registerData.email,
      password: registerData.password,
      firstname: registerData.firstname,
      surname: registerData.surname
    })
  },
  async logout({ commit }) {
    await axios.post('/logout')
    commit('removeUser')
  },
  async updateUser({ commit }, user: User) {
    if (user.iri() === undefined) {
      throw new Error('user is not persisted. Update is not possible')
    }
    const responseUserUpdate = await axios.put(user.iri() as string, user)
    commit('setUserFromResponse', responseUserUpdate)
  }
}
