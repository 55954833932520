import axios, { AxiosResponse } from 'axios'
import { Sprint } from '@/models/Sprint.model'
import { Product } from '@/models/Product.model'

export class SprintService {
  static async getSprints(product: Product, page: number): Promise<Sprint[]> {
    const responseSprints = await axios.get<AxiosResponse>('/sprints', {
      params: {
        ...this.defaultParams(product, page)
      }
    })
    return SprintService.mapSprints(responseSprints)
  }

  static async getActiveSprints(product: Product, page: number): Promise<Sprint[]> {
    const responseSprints = await axios.get<AxiosResponse>('/sprints', {
      params: {
        ...this.defaultParams(product, page),
        isActive: true
      }
    })
    return SprintService.mapSprints(responseSprints)
  }

  static mapSprints(response: AxiosResponse): Sprint[] {
    return (response.data as any)['hydra:member'].map((sprintData: any) => {
      return new Sprint(sprintData, sprintData['@id'])
    })
  }

  static defaultParams(product: Product, page: number): object {
    return {
      product: product.iri(),
      page: page
    }
  }
}
