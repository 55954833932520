





















import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { User } from '@/models/User.model'

@Component
export default class AppBarUserIcon extends Vue {
  async logout() {
    await this.$store.dispatch('auth/logout')
    await router.push({ name: 'Login' })
  }

  get currentUser(): User {
    return this.$store.state.auth.currentUser
  }
}
