import Vue from 'vue'
import '@/plugins/axios'
import '@/plugins/vue-infinite-loading'
import '@/plugins/vee-validate'
import VueMarkdown from 'vue-markdown'
import VueClipboard from 'vue-clipboard2'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import i18n from '@/plugins/i18n'
import ConfirmDialog from '@/components/shared/dialog/ConfirmDialog.vue'
import CancelDialog from '@/components/shared/dialog/CancelDialog.vue'
import DateTimeHelper from '@/plugins/helper/DateTimeHelper'

Vue.component('default-layout', DefaultLayout)
Vue.component('unauthorized-layout', UnauthorizedLayout)
Vue.component('confirm-dialog', ConfirmDialog)
Vue.component('cancel-dialog', CancelDialog)
Vue.component('vue-markdown', VueMarkdown)

Vue.config.productionTip = false

Vue.use(DateTimeHelper)
Vue.use(VueClipboard)


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
