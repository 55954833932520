import { ActionTree } from 'vuex'
import axios, { AxiosResponse } from 'axios'
import { RootState } from '../../types'
import { IssuesListState } from './types'
import { Issue } from '@/models/Issue.model'

const endpointIssues = '/issues'

export const actions: ActionTree<IssuesListState, RootState> = {
  async reload({ commit, dispatch }) {
    commit('resetIssues')
    await dispatch('loadChunk')
  },
  async loadChunk({ state, commit }) {
    const responseIssues = await axios.get<AxiosResponse>(endpointIssues, {
      params: {
        page: state.page
      }
    })
    const mappedIssues = (responseIssues.data as any)['hydra:member'].map((issueData: any) => {
      return new Issue(issueData, issueData['@id'])
    })

    if (mappedIssues.length) {
      commit('addIssues', mappedIssues)
      return true
    } else {
      return false
    }
  },
  async createIssue({ dispatch }, issueData: any) {
    const response = await axios.post(endpointIssues, issueData)
    await dispatch('reload')
    return Issue.createFromResponse(response)
  }
}
