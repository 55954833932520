import dayjs, { Dayjs } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install(Vue: any, options: any) {
    Vue.prototype.$dateTime = {
      formatDate(date: Dayjs | string, locale: string, format = 'llll') {
        return dayjs(date)
          .locale(locale)
          .format(format)
      },
      timeLeft(date: Dayjs | string) {
        return dayjs(date).to(dayjs())
      }
    }
  }
}
