
























































import { User } from '@/models/User.model'
import { Component, Vue } from 'vue-property-decorator'
import Sidebar from '@/layouts/components/Sidebar.vue'
import AppBarUserIcon from '@/layouts/components/AppBarUserIcon.vue'
import Size from '@/components/shared/debug/Size.vue'
import { Product } from '@/models/Product.model'
import FeedbackForm from '@/main/feedback/components/FeedbackForm.vue'
import ActiveSprintButtons from '@/main/sprint/component/ActiveSprintButtons.vue'

@Component({
  components: { ActiveSprintButtons, FeedbackForm, Size, AppBarUserIcon, Sidebar }
})
export default class DefaultLayout extends Vue {
  drawer: boolean | null = null

  showFeedback = false

  async created() {
    await this.$store.dispatch('auth/checkIsAuthenticated')
    if (this.currentUser) {
      this.$vuetify.theme.dark = this.currentUser.darkMode()
      this.$i18n.locale = this.currentUser.language()
    }
  }

  get isAuthenticated(): boolean {
    return this.$store.state.auth.isAuthenticated
  }

  get currentUser(): User {
    return this.$store.state.auth.currentUser
  }

  get currentProduct(): Product {
    return this.$store.state.product.currentProduct
  }

  get availableProducts(): Product[] {
    return this.$store.state.product.availableProducts
  }

  closeDialog() {
    this.showFeedback = false
  }

  changeProduct(productIri: string) {
    this.$store.dispatch('product/setCurrentProductByIri', productIri)
    location.reload()
  }
}
