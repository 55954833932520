import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../main/dashboard/views/Dashboard.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'unauthorized' },
    component: () => import('../main/user/views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { layout: 'unauthorized' },
    component: () => import('../main/user/views/Register.vue')
  },
  {
    path: '/product/initial/:joinCode?',
    name: 'CreateOrJoinProduct',
    component: () => import('../main/product/views/CreateOrJoinProduct.vue'),
    props: true
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: () => import('../main/user/views/UserProfile.vue')
  },
  {
    path: '/productBacklog',
    name: 'ProductBacklog',
    component: () => import('../main/issues/views/IssueListView.vue')
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import('../views/Demo.vue')
  },
  {
    path: '/sprints',
    name: 'SprintList',
    component: () => import('../main/sprint/views/SprintListView.vue')
  },
  {
    path: '/sprints/:sprintId',
    name: 'SprintBoard',
    component: () => import('../main/sprint/views/SprintBoard.vue'),
    props: true
  },
  {
    path: '/issues/:issueId',
    name: 'IssueEdit',
    component: () => import('../main/issues/views/IssueEditView.vue'),
    props: true
  },
  {
    path: '/products',
    name: 'ProductList',
    component: () => import('../main/product/views/ProductListView.vue')
  },
  {
    path: '/products/:productId',
    name: 'ProductDetails',
    component: () => import('../main/product/views/ProductDetailsView.vue'),
    props: true
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: () => import('../views/Changelog.vue')
  },
  {
    path: '/imprint',
    name: 'Imprint',
    meta: { layout: 'unauthorized' },
    component: () => import('../views/Imprint.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: { layout: 'unauthorized' },
    component: () => import('../views/Privacy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const routesUnauthorized = ['Login', 'Register', 'Imprint', 'Privacy']

router.beforeEach((to, from, next) => {
  if (!to.name || !routesUnauthorized.includes(to.name)) {
    store.state.auth.isAuthenticated
      ? next()
      : next({
          name: 'Login',
          params: {
            redirectUrl: to.path
          }
        })
  } else next()
})

export default router
