import { MutationTree } from 'vuex'
import { IssuesListState } from '@/store/issues/list/types'
import { Issue } from '@/models/Issue.model'

export const mutations: MutationTree<IssuesListState> = {
  resetIssues(state: IssuesListState) {
    state.list = []
    state.page = 1
  },
  addIssues(state: IssuesListState, issues: Issue[]) {
    state.page += 1
    state.list.push(...issues)
  },
  addIssue(state: IssuesListState, issue: Issue) {
    state.list.push(issue)
  }
}
