



























import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { FeedbackService } from '@/main/feedback/services/FeedbackService'

@Component
export default class FeedbackForm extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  isLoading = false
  confirmCancel = false
  formFeedbackData = {
    feedbackText: ''
  }

  async save() {
    try {
      this.isLoading = true
      await FeedbackService.postFeedback(this.formFeedbackData)
      this.$emit('feedbackSent')
      this.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }
  cancel(force: boolean) {
    if (!force && this.$refs.form.flags.dirty) {
      this.confirmCancel = true
      return
    }
    this.resetForm()
    this.$emit('canceled')
  }
  resetForm() {
    this.formFeedbackData = {
      feedbackText: ''
    }

    this.$nextTick(() => {
      this.$refs.form.reset()
    })
    this.$emit('canceled')
  }
}
