import { AxiosResponse } from 'axios'
import { LexoRank } from 'lexorank'
import { IssueTransition } from '@/models/interface/IssueTransition'
import { TransitionHistory } from '@/models/interface/TransitionHistory'
import { HasIri } from '@/models/interface/HasIri'

export class Issue implements HasIri {
  id?: string
  title: string
  description: string
  currentStatus: string
  rank: string
  enabledTransitions: IssueTransition[]
  transitionHistory: TransitionHistory[]
  type: string
  estimation: string
  productIri: string
  assignedToIri: string
  assignedToData: []
  tasks: Issue[]
  parentIssue: string

  constructor(data: any, uri?: string) {
    this.id = uri
    this.title = data.title
    this.description = data.description
    this.currentStatus = data.currentStatus
    this.rank = data.rank
    this.enabledTransitions = data.enabledTransitions
    this.transitionHistory = data.transitionHistory
    this.type = data.type
    this.estimation = data.estimation
    this.productIri = data.product
    this.assignedToIri = data.assignedTo
    this.assignedToData = data.assignedToData
    this.tasks = []
    if (data.tasks) {
      this.tasks = data.tasks.map(
        (issueData: { [x: string]: string | undefined }) => new Issue(issueData, issueData['@id'])
      )
    }
    this.parentIssue = data.parentIssue
  }

  iri(): string {
    return this.id ? this.id : ''
  }

  static createFromResponse(response: AxiosResponse): Issue {
    return new Issue(response.data, response.data['@id'])
  }

  static createFromData(data: any): Issue {
    return new Issue(data, data['@id'])
  }

  static createFromIssue(issue: Issue): Issue {
    return new Issue(issue.toJson(), issue.id)
  }

  lexoRank(): LexoRank | null {
    if (this.rank) {
      return LexoRank.parse(this.rank)
    }
    return null
  }

  tasksTodo(): Issue[] {
    return this.tasks.filter(task => task.currentStatus === 'todo')
  }
  tasksDoing(): Issue[] {
    return this.tasks.filter(task => task.currentStatus === 'doing')
  }
  tasksDone(): Issue[] {
    return this.tasks.filter(task => task.currentStatus === 'done')
  }

  isTodo(): boolean {
    return this.currentStatus === 'todo'
  }

  isDoing(): boolean {
    return this.currentStatus === 'doing'
  }

  isDone(): boolean {
    return this.currentStatus === 'done'
  }

  isIssue(): boolean {
    return this.type === 'issue'
  }

  isTask(): boolean {
    return this.type === 'task'
  }

  toJson() {
    return {
      title: this.title,
      description: this.description,
      currentStatus: this.currentStatus,
      rank: this.rank,
      enabledTransitions: this.enabledTransitions,
      transitionHistory: this.transitionHistory,
      type: this.type,
      estimation: this.estimation,
      productIri: this.productIri,
      assignedTo: this.assignedToIri,
      assignedToData: this.assignedToData,
      tasks: this.tasks,
      parentIssue: this.parentIssue
    }
  }
}
