import axios, { AxiosResponse } from 'axios'
import { Issue } from '@/models/Issue.model'
import store from '../../../store/index'
import { Sprint } from '@/models/Sprint.model'

export class IssueService {
  static async getIssues(page: number) {
    const responseIssues = await axios.get<AxiosResponse>('/issues', {
      params: {
        page: page
      }
    })
    return IssueService.mapIssues(responseIssues)
  }

  static mapIssues(response: AxiosResponse) {
    return (response.data as any)['hydra:member'].map((issueData: any) => {
      return new Issue(issueData, issueData['@id'])
    })
  }

  static async countIssuesForCurrentProduct() {
    const currentProduct = store.state.product.currentProduct
    if (!currentProduct) {
      throw new Error('No current product')
    }

    const responseIssues = await axios.get<AxiosResponse>('/issues', {
      params: {
        product: currentProduct.iri()
      }
    })

    return (responseIssues.data as any)['hydra:totalItems']
  }

  static async getIssuesForCurrentProduct(page: number) {
    const currentProduct = store.state.product.currentProduct
    if (!currentProduct) {
      return this.getIssues(page)
    }

    const responseIssues = await axios.get<AxiosResponse>('/issues', {
      params: {
        prepareForNewSprint: false,
        type: 'issue',
        isInActiveOrFutureSprint: false,
        currentStatus: ['todo', 'doing'],
        product: currentProduct.iri(),
        page: page
      }
    })
    return IssueService.mapIssues(responseIssues)
  }

  static async getIssuesForNextSprint() {
    const currentProduct = store.state.product.currentProduct
    if (!currentProduct) {
      throw new Error('Can not get issues for sprint without (current) product')
    }

    const responseIssues = await axios.get<AxiosResponse>('/issues', {
      params: {
        prepareForNewSprint: true,
        currentStatus: ['todo', 'doing'],
        product: currentProduct.iri()
      }
    })
    return IssueService.mapIssues(responseIssues)
  }

  static async getIssuesForSprint(sprint: Sprint) {
    const responseIssues = await axios.get<AxiosResponse>('/issues', {
      params: {
        sprints: sprint.iri()
      }
    })
    return IssueService.mapIssues(responseIssues)
  }

  static async createIssue(formData: any) {
    if (!store.state.product.currentProduct) {
      throw new Error('No current product')
    }
    formData.type = 'issue'
    formData.product = store.state.product.currentProduct.iri()
    return await store.dispatch('issuesList/createIssue', formData)
  }

  static async createTask(issue: Issue, formData: any) {
    if (!store.state.product.currentProduct) {
      throw new Error('No current product')
    }
    formData.type = 'task'
    formData.parentIssue = issue.iri()
    formData.product = store.state.product.currentProduct.iri()
    const response = await axios.post('/issues', formData)

    return Issue.createFromResponse(response)
  }

  static updateRank(issuesToUpdate: Issue[]) {
    issuesToUpdate.forEach(issue => {
      const uri = issue.iri()
      if (uri) {
        axios.put<AxiosResponse>(uri, {
          rank: issue.rank
        })
      }
    })
  }
}
