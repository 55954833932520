import { MutationTree } from 'vuex'
import { ProductState } from '@/store/product/types'
import { Product } from '@/models/Product.model'

export const mutations: MutationTree<ProductState> = {
  setAvailableProducts(state: ProductState, products: Product[]) {
    state.availableProducts = products
  },
  setCurrentProduct(state: ProductState, product: Product) {
    state.currentProduct = product
    localStorage.currentProductIri = product.iri()
  }
}
