import { MutationTree } from 'vuex'
import { AuthState } from './types'
import { User } from '@/models/User.model'
import { AxiosResponse } from 'axios'

export const mutations: MutationTree<AuthState> = {
  setUser(state, user: User) {
    state.isAuthenticated = true
    state.currentUser = user
  },
  setUserFromResponse(state, response: AxiosResponse) {
    state.isAuthenticated = true
    state.currentUser = User.createFromResponse(response)
  },
  removeUser(state) {
    state.isAuthenticated = false
    state.currentUser = undefined
  }
}
