import axios, { AxiosResponse } from 'axios'
import { Product } from '@/models/Product.model'

export class ProductService {
  static async getProducts() {
    const responseIssues = await axios.get<AxiosResponse>('/products')
    return ProductService.mapProducts(responseIssues)
  }

  static mapProducts(response: AxiosResponse) {
    return (response.data as any)['hydra:member'].map((data: any) => {
      return new Product(data, data['@id'])
    })
  }
}
