





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmDialog extends Vue {
  @Prop() private value!: boolean
  @Prop() private title?: string
  @Prop() private text?: string
  @Prop() private confirmLabel?: string
  @Prop() private declineLabel?: string

  get calculatedTitle() {
    return this.title ? this.title : '' + this.$i18n.t('general.dialog.confirm.title')
  }

  get calculatedText() {
    return this.text ? this.text : '' + this.$i18n.t('general.dialog.confirm.text')
  }

  get calculatedConfirmLabel() {
    return this.confirmLabel ? this.confirmLabel : '' + this.$i18n.t('general.decision.yes')
  }

  get calculatedDeclineLabel() {
    return this.declineLabel ? this.declineLabel : '' + this.$i18n.t('general.decision.no')
  }

  confirm() {
    this.$emit('confirmed', true)
    this.closeModal()
  }
  decline() {
    this.$emit('declined', true)
    this.closeModal()
  }
  closeModal() {
    this.$emit('input', false)
  }
}
