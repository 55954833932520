





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Sprint } from '@/models/Sprint.model'
import { SprintService } from '@/main/sprint/services/SprintService'
import { Product } from '@/models/Product.model'

@Component
export default class ActiveSprintButtons extends Vue {
  @Prop({ default: 'btn' })
  private type!: string

  @Prop({ default: false })
  private separator!: boolean

  private activeSprints: Sprint[] = []

  async created() {
    await this.loadActiveSprints()
  }

  async loadActiveSprints() {
    this.activeSprints = await SprintService.getActiveSprints(this.currentProduct, 1)
  }

  getSprintName(sprint: Sprint) {
    return sprint.name ? sprint.name : 'Sprint ohne Name'
  }

  @Prop()
  private currentProduct!: Product
}
