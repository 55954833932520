import { Module } from 'vuex'
import { RootState } from '../types'
import { AuthState } from '@/store/auth/types'
import { actions } from './actions'
import { mutations } from './mutations'

export const state: AuthState = {
  currentUser: undefined,
  isAuthenticated: undefined
}

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
}
