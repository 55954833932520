












import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Size extends Vue {
  private isDebug =
    window.webpackHotUpdate ||
    (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && typeof console !== 'undefined')

  private windowWidth = ''

  onResize() {
    this.windowWidth = window.innerWidth + 'px'
  }

  created() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
