import { AxiosResponse } from 'axios'
import { UserSettings } from '@/models/interface/UserSettings'
import { HasIri } from '@/models/interface/HasIri'

export class User implements HasIri {
  id?: string
  email: string
  firstname: string
  surname: string
  avatarImageUrl?: string
  roles: string[]
  settings: UserSettings | null
  productUris: string[]

  constructor(data: any, uri?: string) {
    this.id = uri
    this.email = data.email
    this.firstname = data.firstname
    this.surname = data.surname
    this.avatarImageUrl = data.avatarImageUrl
    this.roles = data.roles
    if (data.settings === null) {
      this.settings = {
        darkMode: false,
        language: 'de'
      }
    } else {
      this.settings = data.settings
    }
    this.productUris = data.products
  }

  static createFromResponse(response: AxiosResponse) {
    return User.createFromData(response.data)
  }

  static createFromData(data: any) {
    return new User(data, data['@id'])
  }

  static createFromUser(user: User) {
    return new User(
      {
        email: user.email,
        firstname: user.firstname,
        surname: user.surname,
        avatarImageUrl: user.avatarImageUrl,
        roles: user.roles,
        settings: user.settings,
        productUris: user.productUris
      },
      user.id
    )
  }

  iri(): string {
    return this.id ? this.id : ''
  }

  fullname() {
    return this.firstname + ' ' + this.surname
  }

  avatarImageUrlSmall() {
    return this.avatarImageUrl
  }

  avatarImageUrlBig() {
    return this.avatarImageUrl + '?s=600'
  }

  darkMode() {
    if (this.settings) {
      return this.settings.darkMode
    }
    return false
  }

  language() {
    if (this.settings) {
      return this.settings.language
    }
    return 'de'
  }
}
