import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#45C1C9',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#30878c',
        secondary: '#a6a6a6',
        accent: '#003180',
        error: '#b30000',
        info: '#0961aa',
        success: '#275929',
        warning: '#997300'
      }
    }
  }
})
