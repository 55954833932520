import { AxiosResponse } from 'axios'
import { HasIri } from '@/models/interface/HasIri'

export class Product implements HasIri {
  id?: string
  name: string
  description: string
  joinCode: string

  constructor(data: any, uri?: string) {
    this.id = uri
    this.name = data.name
    this.description = data.description
    this.joinCode = data.joinCode
  }

  iri(): string {
    return this.id ? this.id : ''
  }

  static createFromResponse(response: AxiosResponse) {
    return new Product(response.data, response.data['@id'])
  }

  static createFromProduct(product: Product) {
    return new Product(
      {
        name: product.name,
        description: product.description,
        joinCode: product.joinCode
      },
      product.id
    )
  }
}
