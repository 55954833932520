






import { Component, Vue } from 'vue-property-decorator'
import Size from '@/components/shared/debug/Size.vue'
@Component({
  components: { Size }
})
export default class UnauthorizedLayout extends Vue {}
